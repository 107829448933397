import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatCardImage } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [CommonModule, NgOptimizedImage, MatCardImage, MatProgressSpinner, MatButton, MatIcon, RouterLink]
})
export class HomeComponent {
}
