import { Component, computed, Inject, OnInit, signal } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { AuthService, DialogService, IdentityService, StorageService } from '@core/services';
import { MonacoEditorService } from '@core/services/monaco-editor.service';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { AboutDialogComponent, AboutDialogData } from './about-dialog/about-dialog.component';
import { environment } from '@env';
import { BreakpointObserver } from '@angular/cdk/layout';
import { registerSvgIcons } from './app.icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MsalModule,
    RouterLink,
    RouterOutlet,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class AppComponent implements OnInit {
  readonly title = signal('Connect Platform Portal');

  readonly msalAuthenticated = signal(false);
  readonly canViewMembers = computed(() => this.identityService.isSuperAdmin());
  readonly canViewWorkspaces = computed(() =>
     this.identityService.isSuperAdmin()
     || this.identityService.isAdmin());

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly identityService: IdentityService,
    private readonly monacoEditorService: MonacoEditorService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly msalService: MsalService,
    private readonly responsive: BreakpointObserver,
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {
    registerSvgIcons();
  }

  ngOnInit() {
    this.monacoEditorService.load();
    this.msalService.handleRedirectObservable().subscribe();

    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((_result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setMsalAuthenticatedState();
          this.router.navigate(['workspaces']);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.setMsalAuthenticatedState();
      });

    this.responsive.observe([
      '(max-width: 399px)',
      '(max-width: 459px)',
      '(max-width: 549px)'])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        let title = 'Connect Platform Portal';

        if (breakpoints['(max-width: 399px)']) {
          title = '';
        } else if (breakpoints['(max-width: 459px)']) {
          title = 'Platform';
        }
        else if (breakpoints['(max-width: 549px)']) {
          title = 'Platform Portal';
        }

        this.title.set(title);
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  async onAbout() {
    await this.dialogService.show(AboutDialogComponent, {
      width: '650px',
      data: new AboutDialogData(
        this.msalAuthenticated(), 'Platform Portal',
        environment.buildId, environment.buildNumber,
        environment.pipeline, environment.branch,
        environment.stage, environment.repository, environment.commit,
        environment.environmentId, environment.environmentName)
    });
  }

  onLogin() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  onLogout() {
    this.authService.logout();
    this.msalService.logoutRedirect();
  }

  async setMsalAuthenticatedState(): Promise<void> {
    const authenticated = this.msalAuthenticated();
    const accounts = this.msalService.instance.getAllAccounts();
    if (!authenticated && accounts.length > 0) {
      this.storageService.set('viewingBin', false);
      await this.authService.login();
    }
    this.msalAuthenticated.set(accounts.length > 0);
  }
}
