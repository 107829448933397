import { Component, inject, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { ServiceVersion } from '@core/models';
import {
  AdminApiService, AuthApiService, DocumentApiService, IdentityService, StorageApiService
} from '@core/services';
import { environment } from '@env';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MatDivider } from '@angular/material/divider';

export class AboutDialogData {
  constructor(
    public isLoggedIn: boolean,
    public name: string,
    public buildId: string,
    public buildNumber: string,
    public pipeline: string,
    public branch: string,
    public stage: string,
    public repository: string,
    public commit: string,
    public environmentId: string,
    public environmentName: string) { }
}

class Utility {
  static getBranchName(path: string) : string {
    return path.startsWith('refs/heads/') ? path.substring(11) : path;
  }
}

@Pipe({
    name: 'branchName',
    standalone: true
})
export class BranchNamePipe implements PipeTransform {
  transform(path: string): string {
    return Utility.getBranchName(path);
  }
}

@Pipe({
    name: 'leftString',
    standalone: true
})
export class LeftStringPipe implements PipeTransform {
  transform(value: string, length = 8): string {
      return value.substring(0, length);
  }
}

@Component({
    selector: 'app-about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatButton, NgIf, NgOptimizedImage, MatDivider, MatDialogActions, MatDialogClose, BranchNamePipe, LeftStringPipe]
})
export class AboutDialogComponent implements OnInit {
  private readonly gitUrl
    = 'https://dev.azure.com/bsi-online/Commercial%20Solutions/_git/';
  private readonly envUrl
    = 'https://dev.azure.com/bsi-online/Commercial%20Solutions/_environments/';
  private readonly buildUrl
    = 'https://dev.azure.com/bsi-online/Commercial%20Solutions/_build/'

  data: AboutDialogData;

  adminApiVersion?: ServiceVersion;
  authApiVersion?: ServiceVersion;
  documentApiVersion?: ServiceVersion;
  storageApiVersion?: ServiceVersion;
  name?: string;
  email?: string;

  isProduction = environment.production;

  constructor(
    public readonly adminService: AdminApiService,
    public readonly authService: AuthApiService,
    public readonly documentService: DocumentApiService,
    public readonly storageService: StorageApiService,
    public readonly dialogRef: MatDialogRef<AboutDialogComponent>,
    public readonly identityService: IdentityService,
  ) {
    this.data = inject(MAT_DIALOG_DATA);
  }

  ngOnInit(): void {
    if (this.data.isLoggedIn) {
      this.adminService.getVersion().then(v => this.adminApiVersion = v);
      this.authService.getVersion().then(v => this.authApiVersion = v);
      this.documentService.getVersion().then(v => this.documentApiVersion = v);
      this.storageService.getVersion().then(v => this.storageApiVersion = v);
      this.name = this.identityService.name();
      this.email = this.identityService.email();
    }
  }

  openRepo(repo: string) {
    window.open(`${this.gitUrl}${repo}`);
  }

  openBranch(repo: string, branch: string) {
    const msg = `${this.gitUrl}${repo}?version=GB${Utility.getBranchName(branch).replace('/','%2F')}`;
    window.open(msg);
  }

  openCommit(repo: string, commit: string) {
    window.open(`${this.gitUrl}${repo}/commit/${commit}`);
  }

  openEnvironment(environmentId: string) {
    window.open(`${this.envUrl}${environmentId}?view=resources`);
  }

  openBuild(buildId: string) {
    window.open(`${this.buildUrl}results?buildId=${buildId}&view=results`);
  }
}
